var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CreateUpdateTemplate',{attrs:{"routePreventDialog":_vm.routePreventDialog,"customClass":"user-create create-form"},scopedSlots:_vm._u([{key:"header-title",fn:function(){return [_c('h1',{staticClass:"form-title d-flex"},[_vm._v("Create a new User")])]},proxy:true},{key:"header-action",fn:function(){return [_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button",attrs:{"disabled":_vm.formLoading},on:{"click":_vm.goBack}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"mx-2 custom-bold-button white--text",attrs:{"disabled":!_vm.formValid || _vm.formLoading,"loading":_vm.formLoading,"color":"cyan"},on:{"click":_vm.onSubmit}},[_vm._v(" Save User ")])]},proxy:true},{key:"body",fn:function(){return [_c('v-form',{ref:"userForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formValid"}},[_c('v-container',{staticClass:"user-create-height",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"custom-content-container pt-0",attrs:{"cols":"9","md":9,"sm":9}},[_c('perfect-scrollbar',{staticClass:"scroll",staticStyle:{"position":"relative"},attrs:{"options":{ suppressScrollX: true }}},[_c('v-row',[_c('v-col',{staticClass:"py-0 pt-4",attrs:{"md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"6","lg":"4"}},[_c('label',{staticClass:"font-size-16 font-weight-500 ml-1 required",attrs:{"for":"display_name"}},[_vm._v("Salutation")]),_c('v-select',{attrs:{"items":_vm.salutationList,"dense":"","filled":"","item-text":"text","item-value":"value","item-color":"cyan","placeholder":"Salutation","solo":"","flat":"","color":"cyan","rules":[
                          _vm.validateRules.required(
                            _vm.updateUser.title,
                            'Salutation'
                          ),
                        ]},model:{value:(_vm.updateUser.title),callback:function ($$v) {_vm.$set(_vm.updateUser, "title", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"updateUser.title"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","lg":"4"}},[_c('label',{staticClass:"font-size-16 font-weight-500 ml-1 required",attrs:{"for":"display_name"}},[_vm._v("First Name ")]),_c('v-text-field',{attrs:{"dense":"","filled":"","placeholder":"First Name","solo":"","flat":"","color":"cyan","rules":[
                          _vm.validateRules.required(
                            _vm.updateUser.first_name,
                            'First Name'
                          ),
                          _vm.validateRules.minLength(
                            _vm.updateUser.first_name,
                            'First Name',
                            2
                          ),
                          _vm.validateRules.maxLength(
                            _vm.updateUser.first_name,
                            'First Name',
                            100
                          ),
                        ]},on:{"keyup":_vm.createDisplayName},model:{value:(_vm.updateUser.first_name),callback:function ($$v) {_vm.$set(_vm.updateUser, "first_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"updateUser.first_name"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","lg":"4"}},[_c('label',{staticClass:"font-size-16 font-weight-500 ml-1",attrs:{"for":"display_name"}},[_vm._v("Last Name")]),_c('v-text-field',{attrs:{"dense":"","filled":"","placeholder":"Last Name","solo":"","flat":"","color":"cyan","rules":[
                          /* validateRules.required(
                            updateUser.last_name,
                            'Last Name'
                          ), */
                          _vm.validateRules.minLength(
                            _vm.updateUser.last_name,
                            'Last Name',
                            2
                          ),
                          _vm.validateRules.maxLength(
                            _vm.updateUser.last_name,
                            'Last Name',
                            100
                          ),
                        ]},on:{"keyup":_vm.createDisplayName},model:{value:(_vm.updateUser.last_name),callback:function ($$v) {_vm.$set(_vm.updateUser, "last_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"updateUser.last_name"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"6","lg":"4"}},[_c('label',{staticClass:"font-size-16 font-weight-500 ml-1 required",attrs:{"for":"display_name"}},[_vm._v("User Name")]),_c('v-text-field',{attrs:{"dense":"","filled":"","placeholder":"User Name","solo":"","flat":"","color":"cyan","rules":[
                          _vm.validateRules.required(
                            _vm.updateUser.user_name,
                            'User Name'
                          ),
                          _vm.validateRules.minLength(
                            _vm.updateUser.user_name,
                            'User Name',
                            2
                          ),
                          _vm.validateRules.maxLength(
                            _vm.updateUser.user_name,
                            'User Name',
                            100
                          ),
                        ]},model:{value:(_vm.updateUser.user_name),callback:function ($$v) {_vm.$set(_vm.updateUser, "user_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"updateUser.user_name"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","lg":"4"}},[_c('label',{staticClass:"font-size-16 font-weight-500 ml-1 required",attrs:{"for":"display_name"}},[_vm._v("User Email")]),_c('v-text-field',{attrs:{"dense":"","filled":"","placeholder":"User Email","solo":"","flat":"","color":"cyan","rules":[
                          _vm.validateRules.validEmail(
                            _vm.updateUser.user_email,
                            'User Email'
                          ),
                          _vm.validateRules.required(
                            _vm.updateUser.user_email,
                            'User Email'
                          ),
                          _vm.validateRules.minLength(
                            _vm.updateUser.user_email,
                            'User Email',
                            2
                          ),
                          _vm.validateRules.maxLength(
                            _vm.updateUser.user_email,
                            'User Email',
                            100
                          ),
                        ]},model:{value:(_vm.updateUser.user_email),callback:function ($$v) {_vm.$set(_vm.updateUser, "user_email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"updateUser.user_email"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","lg":"4"}},[_c('label',{staticClass:"font-size-16 font-weight-500 ml-1 required",attrs:{"for":"display_name"}},[_vm._v("Phone Number")]),_c('PhoneTextField',{attrs:{"id":"t-primary_phone","value":_vm.updateUser.phone_number,"required":""},model:{value:(_vm.updateUser.phone_number),callback:function ($$v) {_vm.$set(_vm.updateUser, "phone_number", $$v)},expression:"updateUser.phone_number"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"6"}},[_c('label',{staticClass:"font-size-16 font-weight-500 ml-1 required",attrs:{"for":"display_name"}},[_vm._v("Role")]),_c('v-select',{attrs:{"items":_vm.roles,"dense":"","filled":"","item-text":"name","item-value":"id","item-color":"cyan","placeholder":"Role","solo":"","flat":"","color":"cyan","hide-details":"","rules":[
                          _vm.validateRules.required(_vm.updateUser.role, 'Role'),
                        ]},model:{value:(_vm.updateUser.role),callback:function ($$v) {_vm.$set(_vm.updateUser, "role", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"updateUser.role"}}),(_vm.updateUser.role == 1)?_c('em',{staticClass:"font-size-10"},[_vm._v("Unrestricted access to all modules.")]):_vm._e()],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6"}},[_c('label',{staticClass:"font-size-16 font-weight-500 ml-1 required",attrs:{"for":"display_name"}},[_vm._v("Display Name")]),_c('v-text-field',{attrs:{"dense":"","filled":"","placeholder":"Display Name","solo":"","flat":"","color":"cyan","rules":[
                          _vm.validateRules.required(
                            _vm.updateUser.display_name,
                            'Display Name'
                          ),
                          _vm.validateRules.minLength(
                            _vm.updateUser.display_name,
                            'Display Name',
                            2
                          ),
                          _vm.validateRules.maxLength(
                            _vm.updateUser.display_name,
                            'Display Name',
                            100
                          ),
                        ]},model:{value:(_vm.updateUser.display_name),callback:function ($$v) {_vm.$set(_vm.updateUser, "display_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"updateUser.display_name"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"4"}},[_c('label',{staticClass:"font-size-16 font-weight-500 ml-1 required",attrs:{"for":"Designation"}},[_vm._v("Designation")]),_c('v-autocomplete',{staticClass:"width-100 new-height",attrs:{"items":_vm.userDesignations,"dense.":"","filled":"","color":"cyan","item-color":"cyan","solo":"","flat":"","append-outer-icon":"mdi-cog","placeholder":"Select Designation","item-text":"text","item-value":"value","rules":[
                          _vm.validateRules.required(
                            _vm.updateUser.designation,
                            'Designation'
                          ),
                        ]},on:{"click:append-outer":function($event){_vm.manageBrandDialog = true}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s('No Designation(s) Found.')}})],1)],1)]},proxy:true}]),model:{value:(_vm.updateUser.designation),callback:function ($$v) {_vm.$set(_vm.updateUser, "designation", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"updateUser.designation"}}),[_c('ManageUserdesignation',{attrs:{"dialog":_vm.manageBrandDialog,"e_designation":_vm.userDesignations},on:{"close-dialog":function($event){_vm.manageBrandDialog = false},"get-e-designation":_vm.getOptions}})]],2)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"custom-sidebar-container white lighten-3 text-center",attrs:{"cols":"3","md":"3","sm":"3"},on:{"mouseleave":function($event){_vm.imageTemplate = false},"mouseover":function($event){_vm.imageTemplate = true}}},[[_c('v-img',{staticClass:"custom-grey-border custom-border-radius-50 company-image-logo margin-auto",attrs:{"lazy-src":_vm.$defaultProfileImage,"src":_vm.getProfileImage,"max-height":"200","max-width":"200"}}),_c('div',{staticClass:"margin-auto"},[_c('v-btn',{staticClass:"my-4 upload-btn custom-bold-button width-100",attrs:{"outlined":"","small":"","color":"cyan","loading":_vm.imageLoading,"disabled":_vm.imageLoading},on:{"click":_vm.selectImage}},[(_vm.getProfileImage)?[_vm._v("Change Image")]:[_vm._v("Upload Image")]],2),_c('div',{staticClass:"d-none"},[_c('v-file-input',{ref:"uploadImage",attrs:{"rules":_vm.imageRules,"accept":"image/png, image/jpeg, image/jpg"},on:{"change":_vm.updateProfileImage}})],1)],1)]],2)],1)],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }